<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ path: '/dashboard/news' }"
          >资讯列表</el-breadcrumb-item
        >
      </el-breadcrumb>
      <div class="operation-nav">
        <router-link to="/dashboard/news/add">
          <el-button type="primary" icon="plus">添加资讯</el-button>
        </router-link>
      </div>
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-table :data="tableData" style="width: 100%" border stripe>
          <!-- <el-table-column prop="ID" label="ID" width="80">
            <template scope="scope">
              <div>{{ scope.row.id }}</div>
            </template>
          </el-table-column> -->
          <el-table-column label="主图" width="120">
            <template scope="scope">
              <img
                :src="scope.row.list_pic_url + '=watershark'"
                v-if="scope.row.list_pic_url"
                alt=""
                style="width: 100px; height: 80px"
              />
            </template>
          </el-table-column>

          <el-table-column prop="title" label="标题" width="150">
            <template scope="scope">
              <div>{{ scope.row.title }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="content" label="内容" width="350">
            <template scope="scope">
              <div>{{ scope.row.content.substring(0, 50) }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="create_time" label="发布时间" width="120">
            <template scope="scope">
              <div>{{ scope.row.create_time }}</div>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="view_num" label="阅读量">
            <template scope="scope">
              <div>{{ scope.row.view_num }}</div>
            </template>
          </el-table-column> -->

          <el-table-column prop="view_num" label="阅读量" width="150">
            <template scope="scope">
              <el-button type="success" size="small"
                >{{ scope.row.view_num }}人</el-button
              >
            </template>
          </el-table-column>

          <el-table-column prop="is_show" label="状态">
            <template scope="scope">
              <el-switch
                v-model="scope.row.is_show"
                
                @change="statusChange(scope.row, scope.index)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="250">
            <template scope="scope">
              <el-button
                size="small"
                type="primary"
                @click="handleRowEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="danger"
                @click="handleRowDelete(scope.$index, scope.row)"
                >删除</el-button
              >
              <el-button round type="success" size="medium" @click="openTips"
                >上首页</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-box">
        <el-pagination
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      total: 0,
      filterForm: {
        name: "",
      },
      tableData: [],
    };
  },
  methods: {
    openTips() {
      this.$alert("请联系您的商务经理帮助您开通上首页", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.axios
            .get("dynamic/addClick", {
              params: {
                event: "news",
              },
            })
            .then((response) => {});
        },
      });
    },
    statusChange(row, index) {
      this.axios
        .get("news/changeStatus", {
          params: {
            id: row.id,
            is_show: row.is_show,
          },
        })
        .then((response) => {
          if (response.data.errno === 0) {
              this.$message({
                type: "success",
                message: "修改成功",
              });
            } else {
              this.$message({
                type: "error",
                message: "修改失败",
              });
            }
        });
    },
    handleRowDelete(index, row) {
      this.$confirm("确定要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios.post("news/destory", { id: row.id }).then((response) => {
          if (response.data.errno === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        });
      });
    },
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleRowEdit(index, row) {
      this.$router.push({ name: "news_add", query: { id: row.id } });
    },

    onSubmitFilter() {
      this.page = 1;
      this.getList();
    },
    getList() {
      this.axios
        .get("news", {
          params: {
            page: this.page,
            size: this.size,
            name: this.filterForm.name,
          },
        })
        .then((response) => {
          this.tableData = response.data.data.data;
          this.page = response.data.data.currentPage;
          this.total = response.data.data.count;
        });
    },
  },
  components: {},
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.sub-category .el-table__expanded-cell {
  padding: 0;
}

.bg-gray {
  /* background:gray; */
  color: red;
  font-weight: bold;
}

.bg-left {
  margin-left: 30px;
}
</style>
